<template>
    <v-hover v-slot:default="{hover}">
        <v-card
            class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
            :elevation="hover ? 4 : 2" style="cursor: pointer;">

            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-circle</v-icon>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.seller }}</span>
                        </v-row>

                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                        <v-col v-bind="layout" v-for="v in keys" :key="v.value">
                            <v-row no-gutters align="center">
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys(v.value) }}</v-col>
                                <v-col cols="12" class="gold--text">{{ item[v.value] }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "ProductionReportCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        card: { cols: 7, xs: 4, sm: 3, md: 1, xl: 0, align: 'center', class: 'my-2 full-cols'}
    }),
    computed: {
        layout() {
            return this.card;
        }
    }
}
</script>